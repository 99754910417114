/* @flow */

import React, { useEffect, useRef } from "react";
import cn from "classnames";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { useData } from "crustate/react";
import { useTranslate } from "@awardit/react-use-translate";
import { Link } from "react-router-dom";
import { QuoteData } from "state/data";
import { Wrapper } from "@crossroads/ui-components";
import ButtonLink from "components/ButtonLink";
import useFormat from "helpers/hooks/use-format";
import { useUi, MODE } from "helpers/ui";

import CloseIcon from "icons/close.svg";
import ChevronIcon from "icons/chevron.svg";
import SearchIcon from "icons/search.svg";

import Logo from "components/Logo";

import styles from "./styles.scss";

const Header = (): React$Node => {
  const { closeHamburger: close } = useUi();

  return (
    <Wrapper className={styles.header}>
      <Logo />
      <ButtonLink onClick={() => close(styles.animationDuration)}>
        <CloseIcon className={styles.close} />
      </ButtonLink>
    </Wrapper>
  );
};

const TopMenu = (): React$Node => {
  const t = useTranslate();
  const {
    setModeAndOpenMenu: setMode,
    closeHamburger: close } = useUi();

  return (
    <div className={styles.topMenu}>
      <Link
        className={styles.item}
        to="/all-products"
        onClick={() => {
          close(styles.animationDuration);
        }}
      >
        {t("ALL_PRODUCTS.TITLE")}
      </Link>

      <span className={styles.item} onClick={() => setMode(MODE.CATEGORIES)}>
        <span>{t("HEADER.CATEGORIES")}</span>
        <ChevronIcon className={styles.chevron} />
      </span>

      <span className={styles.item} onClick={() => setMode(MODE.BRANDS)}>
        <span>{t("HEADER.BRANDS")}</span>
        <ChevronIcon className={styles.chevron} />
      </span>

      <Link className={styles.item} to={t("CUSTOMER_SERVICE.LINK")}>
        <span>{t("CUSTOMER_SERVICE.TEXT")}</span>
      </Link>

      <span className={styles.item} onClick={() => setMode(MODE.SEARCH)}>
        <span>
          <SearchIcon />
          <span>{t("HEADER.SEARCH")}</span>
        </span>
      </span>

      <CartButton />
    </div>
  );
};

const CartButton = (): React$Node => {
  const t = useTranslate();
  const { formatPrice } = useFormat();
  const quote = useData(QuoteData).data || null;
  const cartCount = quote?.items.reduce((a, { qty }) => a + qty, 0) || 0;

  return (
    <Link
      className={cn(styles.item, { [styles.linkDisabled]: cartCount === 0 })}
      title={t("HEADER.CART")}
      to={t("URL.CHECKOUT_CART")}
    >
      <span>{t("HEADER.CART")}</span>
      <span>
        <span className={styles.grandTotal}>
          {formatPrice(quote?.grandTotal.incVat || 0)}
        </span>
        <span className={styles.cartCount}>&nbsp;({cartCount})</span>
      </span>
    </Link>
  );
};

const HamburgerMenu = ({ children }: { children: React$Node }): React$Node => {
  const {
    hamburgerOpen,
    subNavOpen,
  } = useUi();

  const ref = useRef();

  useEffect(() => {
    const element = ref.current;

    if (hamburgerOpen && !subNavOpen) {
      disableBodyScroll(element);
    }
    else {
      enableBodyScroll(element);
    }

    return () => {
      if (element) {
        enableBodyScroll(element);
      }
    };
  }, [subNavOpen, hamburgerOpen]);

  return (
    <div className={cn(
      styles.hamburger,
      { [styles.open]: hamburgerOpen },
      { [styles.subNavOpen]: subNavOpen }
    )}
    >
      <div ref={ref} className={styles.hamburgerMenu}>
        <Header />

        <TopMenu />
      </div>

      {children}
    </div>
  );
};

export default HamburgerMenu;
