/* @flow */

import React, { useState, useEffect } from "react";
import { useData, useSendMessage } from "crustate/react";
import { addMessage } from "@crossroads/shop-state/messages";
import { useAnalytics } from "context/analytics";
import RedeemCard from "components/RedeemCard";
import { quote as quoteQuery } from "queries";
import { useClient } from "entrypoint/shared";

import { QuoteData } from "state/data";
import { setQuoteDiscountCode, removeQuoteDiscountCode } from "state/quote";
import { CheckoutCart } from "@crossroads/shop-views";
import useFormat from "helpers/hooks/use-format";

const QUOTE_ITEM_UPDATING_STATES = new Set([
  "UPDATING_ITEM",
  "REMOVING_ITEM",
]);

const Cart = (): React$Node => {
  const quote = useData(QuoteData);
  const analytics = useAnalytics();
  const sendMessage = useSendMessage();
  const { formatPrice } = useFormat();
  const [isUpdatingQuote, setIsUpdatingQuote] = useState<boolean>(false);

  const onSuccess = () => {
    sendMessage(addMessage("success", "success"));
  };

  useEffect(() => {
    if (QUOTE_ITEM_UPDATING_STATES.has(quote.state)) {
      setIsUpdatingQuote(true);
    }
    else {
      setIsUpdatingQuote(false);
    }
  }, [quote.state]);

  if (!quote.data) {
    return null;
  }

  return (
    <CheckoutCart
      analytics={{ context: analytics, hasAlternativeContext: true }}
      QuoteData={QuoteData}
      formatPrice={formatPrice}
      setQuoteDiscountCode={setQuoteDiscountCode}
      removeQuoteDiscountCode={removeQuoteDiscountCode}
      quoteQuery={quoteQuery}
      useClient={useClient}
      RedeemCardField={
        <RedeemCard
          variant="plain"
          isDisabled={isUpdatingQuote}
          onSuccess={onSuccess}
        />
      }
    />
  );
};

export default Cart;
