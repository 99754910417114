/* @flow */

import React, { useEffect } from "react";
import cn from "classnames";
import { useTranslate } from "@awardit/react-use-translate";
import { useLocation } from "react-router";
import usePrevious from "helpers/hooks/use-previous";
import Nav from "./Nav";
import { QuoteData } from "state/data";
import { useData } from "crustate/react";
import { useUi, MODE } from "helpers/ui";
import SystemMessages from "components/SystemMessages";

import styles from "./styles.scss";

const Header = (): React$Node => {
  const location = useLocation();
  const t = useTranslate();
  const { setHamburgerOpen, setSubNavOpen, setViewMode } = useUi();
  const quoteData = useData(QuoteData);
  const prevQuoteData = usePrevious(quoteData);
  const onCheckout = (location ? location.pathname : "").includes("checkout");

  // Open mini cart when adding to cart
  useEffect(() => {
    if (location.pathname === t("URL.CHECKOUT_CART") ||
        !prevQuoteData ||
        (quoteData.state !== "LOADED" || prevQuoteData.state !== "ADDING_ITEM")) {
      return;
    }

    const count = quoteData.data.items.reduce((a, { qty }) => a + qty, 0);
    const prevCount = prevQuoteData.data.items.reduce((a, { qty }) => a + qty, 0);

    if (count > prevCount) {
      setViewMode(MODE.CART);
      setSubNavOpen(false);
      setHamburgerOpen(false);
    }
  }, [setViewMode, prevQuoteData, quoteData, location, setSubNavOpen, setHamburgerOpen]);

  return (
    <>
      <div className={cn(styles.header, { [styles.onCheckout]: onCheckout })}>
        <SystemMessages />
        <Nav onCheckout={onCheckout} />
      </div>
      <div className={styles.placeholder} />
    </>
  );
};

export default Header;
