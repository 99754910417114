/* @flow */

import type { Quote } from "shop-state/types";

import type { Data } from "@crossroads/shop-state/quote";

type SetDiscountCodeRequest = {
  +tag: typeof QUOTE_SET_DISCOUNT_CODE_REQUEST,
  +code: string,
};

type RemoveDiscountCodeRequest = {
  +tag: typeof QUOTE_REMOVE_DISCOUNT_CODE_REQUEST,
};

const QUOTE_SET_DISCOUNT_CODE_REQUEST: "quote/set_discount_code/request" = "quote/set_discount_code/request";
const QUOTE_REMOVE_DISCOUNT_CODE_REQUEST: "quote/remove_discount_code/request" = "quote/remove_discount_code/request";

export const setQuoteDiscountCode = (code: string): SetDiscountCodeRequest => ({
  tag: QUOTE_SET_DISCOUNT_CODE_REQUEST,
  code,
});

export const removeQuoteDiscountCode = (): RemoveDiscountCodeRequest => ({
  tag: QUOTE_REMOVE_DISCOUNT_CODE_REQUEST,
});

export const getQuoteData = (d: Data): ?Quote => {
  return d.data || null;
};
